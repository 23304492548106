<script>
  import { setContext } from "svelte";
  import TrackView from './TrackView.svelte';

  export let currentobjecturl = "";
  export let objecturl = "";
  export let navigationrooturl = "";
  export let defaultquery;
  export let rowlabeltype = "";
  export let colormapping = [];
  export let trackstartkm = 0;
  export let trackendkm = 5;

  if(defaultquery){
    defaultquery = JSON.parse(defaultquery)
  }

  setContext("baseurl", currentobjecturl);
  setContext("objecturl", objecturl);
  setContext("defaultquery", defaultquery);
  setContext("rowlabeltype", rowlabeltype);
  setContext("rooturl", navigationrooturl);
  setContext("colorMapping", colormapping);
  setContext("trackStartKm", trackstartkm);
  setContext("trackEndKm", trackendkm);

</script>

<main>
<h1>Track Overview</h1>

<TrackView></TrackView>


</main>

<style>
  .logo {
    height: 6em;
    padding: 1.5em;
    will-change: filter;
    transition: filter 300ms;
  }
  .logo:hover {
    filter: drop-shadow(0 0 2em #646cffaa);
  }
  .logo.svelte:hover {
    filter: drop-shadow(0 0 2em #ff3e00aa);
  }
  .read-the-docs {
    color: #888;
  }
</style>

